import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Oferta de idiomas</h2>
          <p className="text-justify">
            En LINCK Academy proponemos clases impartidas en períodos bimestrales. Existen varios módulos que se adaptan a la necesidad de cada uno: Ofrecemos cursos regulares (20 horas por bimestre) y cursos intensivos (40 horas por bimestre). Las clases pueden ser entre semana y/o los sábados por la mañana, dependiendo lo que tú prefieras.
          </p>
          <p className="text-justify">Todo el público es bienvenido, ya que contamos con clases para niños, adolescentes, adultos, así también contamos con una modalidad empresarial. En total, nuestro programa de estudios comprende 20 niveles que se dividen en 4 grandes niveles que son: A1, A2, B1 y B2.</p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <img src={d.icon} alt={`${d.name} flag`} className="flag-image" />
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <p>*Todos los niveles de LINCK Academy están definidos por el MCER (Marco Europeo Común de Referencia para las
              Lenguas).</p>
      </div>
    </div>
  );
};
