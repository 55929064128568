import { TikTok } from "react-tiktok";

export const Media = ({ type, title, largeSrc, smallSrc }) => {
  if (type === "youtube") {
    return (
      <div className="portfolio-item video-item">
        <div className="hover-bg">
          <a href={largeSrc} title={title} data-lightbox-gallery="gallery1">
            <iframe
              src={`https://www.youtube.com/embed/${smallSrc}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="img-responsive"
              title={title}
            ></iframe>
          </a>
        </div>
      </div>
    );
  } else if (type === "tiktok") {
    return (
      <div className="portfolio-item video-item">
        <TikTok url={largeSrc} />
      </div>
    );
  } else {
    return (
      <div className="portfolio-item">
        <div className="hover-bg">
          <a href={largeSrc} title={title} data-lightbox-gallery="gallery1">
            <img src={smallSrc} className="img-responsive" alt={title} />
          </a>
        </div>
      </div>
    );
  }
};